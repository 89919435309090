<script>
  export let title = "";
</script>

<div class="h-full w-full grid relative place-items-center">
  <div class="bg-true-gray-500/5 rounded-4 grid transition-all top-2 right-2 bottom-2 left-2 ring-true-gray-200 z-1 absolute place-items-center overflow-hidden group hover:bg-true-gray-900 hover:ring-2">
    <slot><div class="i-svg-spinners-bars-rotate-fade" /></slot>
    <h2 class="font-quicksand font-700 m-0 text-sm p-0 transition-colors top-7 left-7 absolute uppercase group-hover:text-true-gray-50">{title}</h2>
  </div>
</div>

<style>
  h2 {
    letter-spacing: 0.6rem;
  }
</style>
