<script>
  import Blog from "./Blog.svelte";
  import Card from "./Card.svelte";
  import Contact from "./Contact.svelte";
  import Docs from "./Docs.svelte";
  import Domains from "./Domains.svelte";
  import Notice from "./Notice.svelte";
  import Sponsorship from "./Sponsorship.svelte";
  import Wechat from "./Wechat.svelte";
</script>

<div class="h-full w-full absolute">
  <div class="grid grid-(cols-4 rows-2) xl:(absolute left-10 right-10 top-10 bottom-10) <xl:h-200vw <xl:m-10 <xl:grid-cols-2 <xl:grid-rows-4">
    <div class="animate-fade-in animate-ease-out col-span-2 animate-duration-500">
      <Card title="domains"><Domains /></Card>
    </div>
    <div class="animate-fade-in animate-ease-out animate-both animate-duration-500 animate-delay-100">
      <Card title="sponsorship"><Sponsorship /></Card>
    </div>
    <div class="animate-fade-in animate-ease-out animate-both animate-duration-500 animate-delay-200">
      <Card title="notices"><Notice /></Card>
    </div>
    <div class="animate-fade-in animate-ease-out animate-both animate-duration-500 animate-delay-300">
      <Card title="wechat"><Wechat /></Card>
    </div>
    <div class="animate-fade-in animate-ease-out animate-both animate-duration-500 animate-delay-400">
      <Card title="blog"><Blog /></Card>
    </div>
    <div class="animate-fade-in animate-ease-out animate-both animate-duration-500 animate-delay-500">
      <Card title="docs"><Docs /></Card>
    </div>
    <div class="animate-fade-in animate-ease-out animate-both animate-duration-500 animate-delay-600">
      <Card title="contact"><Contact /></Card>
    </div>
  </div>
</div>

<style lang="postcss">
  :global(*:not(html, body, body > * > *)) {
    /* border: solid 0.5pt #42b98330; */
    /* border: solid 0.2pt #80808070; */
  }
</style>
