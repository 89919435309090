<script>
  // @ts-ignore
  import { gtag } from "ga-gtag";
  const href = "https://promplate.dev/";
</script>

<svelte:head>
  <link rel="prefetch" {href} />
</svelte:head>

<a {href} on:click={() => gtag("event", "clickFreeChatFromNotice")} class="h-full w-full absolute">
  <div class="inset-5 transition-all text-cyan-300 translate-y-20 -translate-x-35 duration-400 absolute blur-0.9em group-hover:scale-130 group-hover:blur-1.2em">
    <!-- <svg class="animate-rotate-in animate-ease-out animate-duration-4000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 801.3 785.923">
      <path
        d="M322.192.65c55.923-.024 107.631 29.716 135.732 78.065l3.845 7.14 25.027-14.436c75.042-43.348 171.016-17.654 214.363 57.388 27.905 48.307 28.063 107.797.415 156.252l-4.433 7.296 25.028 14.437c75.05 43.332 100.763 139.3 57.43 214.351-27.883 48.292-79.3 78.159-135.063 78.452l-8.513-.156v28.872c.026 86.662-70.207 156.936-156.869 156.962-55.934.017-107.648-29.743-135.739-78.112l-3.923-7.14-24.988 14.436c-75.042 43.348-171.016 17.655-214.363-57.387-27.905-48.308-28.063-107.798-.415-156.253l4.433-7.296-25.028-14.436C4.08 435.752-21.632 339.784 21.7 264.733c27.883-48.292 79.3-78.158 135.064-78.452l8.512.157v-28.873C165.277 70.903 235.531.65 322.192.65Zm184.454 423.161-2.667 159.27a39.229 39.229 0 0 1-15.339 30.48l-4.276 2.824-76.731 44.329c18.022 39.453 64.615 56.825 104.068 38.803a78.536 78.536 0 0 0 45.668-65.36l.196-5.846v-173.94l-50.919-30.56Zm-79.752 76.379-139.262 77.28a39.229 39.229 0 0 1-38.64-.313l-76.732-44.329c-25.214 35.266-17.065 84.295 18.202 109.509 25.013 17.883 58.164 19.515 84.813 4.176l150.638-86.97.981-59.353Zm105.408-212.424-51.9 28.833 136.595 81.91a39.229 39.229 0 0 1 19.026 33.658v88.618c43.182 4.078 81.494-27.621 85.573-70.803 2.878-30.472-12.209-59.84-38.655-75.246l-150.64-86.97Zm-367.025-22.714c-43.149-4.195-81.529 27.384-85.724 70.533a78.497 78.497 0 0 0 33.628 72.26l5.179 3.256 150.638 86.97 51.9-28.833-136.595-81.91a39.229 39.229 0 0 1-18.712-28.558l-.314-5.06v-88.658ZM322.192 79.108c-43.33 0-78.457 35.126-78.457 78.457v173.94l50.919 30.56 2.667-159.269a39.229 39.229 0 0 1 19.615-33.344l76.731-44.29c-12.735-28.036-40.681-46.043-71.475-46.054Zm311.006 88.97c-20.476-35.475-64.796-49.23-101.76-31.579l-5.413 2.864-150.638 86.97-.981 59.354 139.262-77.281a39.229 39.229 0 0 1 34.05-1.962l4.59 2.276 76.771 44.328c17.34-24.322 20.007-57.47 4.12-84.97Z"
        style="fill:none;fill-rule:evenodd;stroke:currentColor;stroke-linejoin:round;stroke-width:1.3px"
      />
    </svg> -->

    <div class="i-svg-spinners-wind-toy w-full h-full op-5 text-#fcd53f" />

    <!-- <svg class="animate-zoom-in animate-ease-out animate-duration-2000" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
      <path fill="#55449920" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z" />
      <rect width="2" height="7" x="11" y="6" fill="#55449960" rx="1">
        <animateTransform attributeName="transform" dur="9s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
      </rect><rect width="2" height="9" x="11" y="11" fill="#554499" rx="1">
        <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
      </rect>
    </svg> -->
  </div>
  <div class="flex flex-col m-0 p-4 inset-7 absolute items-end justify-end tracking-wider">
    <div class="font-bold m-0 my-4 text-3.5 text-true-gray-400">2023 / 09 / 16</div>
    <p>
      <span>我最近正在开发</span>
      <span>一个提示工程框架</span>
      <span>🙏欢迎大家给仓库点星</span>
    </p>
    <hr />
    <p class="!text-sm !gap-2">
      <span>虽然文档还没完善，但是做了一个主页</span>
      <span>点击这个卡片可以前往主页</span>
      <span>GitHub 链接和 PyPI 链接也在其中</span>
      <span>如果觉得这个项目不错，请为我点一颗星，不胜感激</span>
    </p>
  </div>
</a>

<!-- <a href="https://github.com/CNSeniorious000/free-chat" on:click={() =>  gtag("event", "clickFreeChatFromNotice")} class="h-full w-full absolute">
  <div class="flex flex-col h-full m-0 w-full p-4 right-7 bottom-7 absolute items-end justify-end">
    <p class="font-bold m-0 my-4 text-3.5 text-true-gray-400">2023 / 07 / 22</p>
    <p class="flex flex-col m-0 text-right transition-all text-3xl text-true-gray-300 gap-3.5 vertical-bottom group-hover:text-true-gray-50">
      <span>⭐⭐⭐⭐⭐⭐⭐⭐⭐</span>
      <span>⭐如果你有 GitHub 账号⭐</span>
      <span>⭐能给我点一颗 Star 吗⭐</span>
      <span>⭐⭐⭐⭐⭐⭐⭐⭐⭐</span>
    </p>
  </div>
</a> -->

<!-- <a class="h-full bg-true-gray-100 w-full text-red-500 absolute" href="https://github.com/CNSeniorious000/free-chat" on:click={() =>  gtag("event", "clickFreeChatFromNotice")}>
  <div class="flex flex-col h-full m-0 w-full p-4 right-7 bottom-7 absolute items-end justify-end">
    <p class="font-bold m-0 my-4 opacity-60 text-3.5">2023 / 07 / 22</p>
    <p class="flex flex-col m-0 text-right transition-all text-3xl gap-3.5 vertical-bottom group-hover:text-red-300">
      <span>昨天，我个人的所有</span>
      <span>已备案域名都被封禁了</span>
      <span>因此 chat.*.bnu120.space</span>
      <span>及本页面各种链接都挂了</span>
      <span>见谅🙏</span>
      <span class="text-base">点此前往开源仓库，欢迎自行部署/咨询专线</span>
    </p>
  </div>
</a> -->

<style>
  p {
    --uno: flex flex-col m-0 text-right transition-all text-3xl text-true-gray-300 gap-3.5 vertical-bottom group-hover:text-true-gray-50;
  }

  hr {
    --uno: border-none h-0.1em bg-true-gray-50/10 group-hover:border-true-gray-50/10 rounded-full my-4 mr-0 w-80%;
  }
</style>
