<script>
  import { gtag } from "ga-gtag";
  import { onMount } from "svelte";
  import { scale } from "svelte/transition";

  let show = false;

  onMount(() => setTimeout(() => (show = true), 200));
</script>

<div class="h-full w-full grid p-30 translate-y--11 absolute place-items-center">
  {#if show}
    <img in:scale={{ start: 0.9, duration: 1000, delay: 250 }} class="max-w-25% max-h-25% opacity-80 transition-all duration-500 absolute group-hover:opacity-100 group-hover:scale-110" src="sponsorship.svg" alt="微信赞赏码" />
    <img in:scale={{ start: 0.8, duration: 700, delay: 100 }} class="max-w-25% max-h-25% transition-all rounded-2xl scale-25 duration-500 absolute group-hover:scale-35" src="https://s2.loli.net/2023/11/06/Sju95mL8RlWHnoZ.png" alt="图标" />
  {/if}
</div>
<div class="flex flex-col text-center w-full bottom-15% text-true-gray-200 gap-2.5 absolute tracking-widest">
  <p><strong>LLM普惠的推进离不开大家的持续捐赠</strong></p>
  <p>推荐使用上面的捐赠码</p>
  <p>捐赠留言无法回复，欢迎<a class="border-b-solid border-b-0.1em p-b-0.1em border-b-white/30 hover:border-b-white/60 transition-border-color ml-0.1em" href="mailto:kilo.meter@foxmail.com">邮箱联系</a></p>
</div>
<div class="text-center w-full bottom-0 absolute">
  <a on:click={() => gtag("event", "clickSponsorshipFromSponsorship")} href="https://tips.free-chat.asia/sponsorship" class="w-full grid py-3 transition-all ring-true-gray-700 place-items-center hover:text-true-gray-900 hover:!bg-true-gray-200 group-hover:bg-true-gray-800 group-hover:ring-1">
    <div class="flex flex-row gap-1 items-center">
      <span class="transition-all">支付宝等其它捐赠方式</span>
      <span class="opacity-80 i-ic-round-play-arrow inline-block" />
    </div>
  </a>
</div>

<style>
  img {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1.3);
  }
  p {
    --uno: m-0;
  }
</style>
