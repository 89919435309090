<script>
  // @ts-ignore
  import { gtag } from "ga-gtag";
</script>

<div class="h-full w-full grid p-30 translate-y--5 absolute place-items-center">
  <img class="max-w-25% max-h-25% opacity-70 transition-all scale-90 duration-500 -translate-y-3 absolute group-hover:opacity-100 group-hover:scale-100" src="/free-chat-telegram-qr.png" alt="入群二维码" />
  <!-- <img class="max-w-25% max-h-25% transition-all scale-70 duration-500 absolute group-hover:scale-100" src="siteLogoBlurred.png" alt="图标" /> -->
</div>
<div class="flex flex-col text-center w-full bottom-20% text-true-gray-200 gap-2 absolute items-center">
  <span>此为 telegram 入群码，微信群太麻烦</span>
  <span>建议以邮件方式联系我</span>
</div>
<div class="text-center w-full bottom-0 absolute">
  <a on:click={() => gtag("event", "clickWeChatFromWeChat")} href="https://group.free-chat.asia/" class="w-full grid py-3 transition-all ring-true-gray-700 place-items-center hover:text-true-gray-900 hover:!bg-true-gray-200 group-hover:bg-true-gray-800 group-hover:ring-1">
    <div class="flex flex-row gap-1 items-center">
      <span class="transition-all">之前还做过一个入群页面，点这里查看</span>
      <span class="opacity-80 i-ic-round-play-arrow inline-block" />
    </div>
  </a>
</div>

<style>
  img {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1.3);
  }
</style>
