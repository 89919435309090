<script lang="ts">
  import { onMount } from "svelte";
  let show = false;
  onMount(() => setTimeout(() => (show = true), 400));

  export let name = "";
  export let items: {title: string, slug: string, direct?: boolean, href: string}[] = [];
</script>

{#if show}
  <div>
    {#each items as { title, slug, direct, href }, i}
      <a style:--i="{i * 100}ms" href={direct ? href : `/${name}/${slug}`}>{title}</a>
      <hr style:--i="{i * 100 + 50}ms" />
    {/each}
  </div>
{/if}

<style>
  div {
    --uno: flex flex-col w-full top-18 absolute;
  }

  a,
  hr {
    --uno: animate-(fade-in delay-$i both duration-400) transition-all;
  }

  a:hover + hr,
  hr:has(+ a:hover) {
    --uno: \!border-b-transparent;
  }

  hr {
    border: none;
    border-bottom: solid 1px;
    --uno: border-true-gray-800/80 h-0 my-0 mx-7 group-hover:border-true-gray-800;
  }

  a {
    --uno: relative rounded mx-4 py-2.6 px-3 hover:bg-true-gray-800 ring-true-gray-300 active:ring-1.5;
    --uno: text-true-gray-200 hover:text-true-gray-50;
    --uno: visited:(text-true-gray-400 hover:text-true-gray-100);
  }
</style>
